import PageHead from "../Head";
import BackToTop from "../backToTop";

import MobileMenu from "@/components/Header/MobileMenu";
import Cart from "@/components/Header/Offcanvas/Cart";
import HeaderStyleNine from "@/components/Header/HeaderStyle-Nine";
import Marketplace from "@/components/12-Marketplace/12-Marketplace";
import FooterThree from "@/components/Footer/Footer-Three";
import Separator from "@/components/Common/Separator";

const MarketplacePage = () => {
  return (
    <>
      <PageHead title="Mentorbliss - New learning platform" />
          <MobileMenu />
          <HeaderStyleNine headerType="" />
          <Marketplace />
          <Cart />

          <BackToTop />
          <Separator />
          <FooterThree />
    </>
  );
};

export default MarketplacePage;
